import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import InputSection from "./InputSection";

const Controller = forwardRef(
  ({ handleNewQuestion, updateResponse, isSourceCollapsed }, ref) => {
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [startYearInput, setStartYearInput] = useState("");
    const [endYearInput, setEndYearInput] = useState("");
    const [selectedJournals, setSelectedJournals] = useState([]);
    const textareaRef = useRef(null);

    const eventSourcesRef = useRef({});

    const fetchQuestionAnswer = useCallback(
      (question, responseIndex, startYear, endYear, journals) => {
        const url = new URL(
          `${process.env.REACT_APP_API_URL}/question_answer_stream`
        );
        url.searchParams.append("query", question);
        if (startYear) {
          url.searchParams.append("mindate", startYear);
        }
        if (endYear) {
          url.searchParams.append("maxdate", endYear);
        }
        if (journals && journals.length > 0) {
          journals.forEach((journal) =>
            url.searchParams.append("journals", journal)
          );
        }

        const eventSource = new EventSource(url);

        // Store the EventSource
        eventSourcesRef.current[responseIndex] = eventSource;

        eventSource.onopen = () => {
          setIsLoading(true);
        };

        eventSource.onmessage = (event) => {
          updateResponse(responseIndex, event.data);
        };

        eventSource.addEventListener("sources", (event) => {
          try {
            const jsonData = JSON.parse(event.data);
            if (Array.isArray(jsonData)) {
              updateResponse(responseIndex, jsonData, true);
              setIsLoading(false);
              eventSource.close();
              delete eventSourcesRef.current[responseIndex];
            }
          } catch (error) {
            setIsLoading(false);
            eventSource.close();
            delete eventSourcesRef.current[responseIndex];
          }
        });

        eventSource.onerror = (error) => {
          setIsLoading(false);
          eventSource.close();
          delete eventSourcesRef.current[responseIndex];
        };
      },
      [updateResponse]
    );

    const handleSubmit = (event) => {
      event.preventDefault();
      if (query.trim()) {
        setIsLoading(true);
        // Collect tags
        const tags = [];
        if (startYearInput !== "") {
          tags.push({
            name: "Start Year",
            value: startYearInput,
            backgroundColor: "bg-orange-100",
            textColor: "text-orange-800",
            darkBackgroundColor: "dark:bg-orange-900",
            darkTextColor: "dark:text-orange-300",
          });
        }
        if (endYearInput !== "") {
          tags.push({
            name: "End Year",
            value: endYearInput,
            backgroundColor: "bg-green-100",
            textColor: "text-green-800",
            darkBackgroundColor: "dark:bg-green-900",
            darkTextColor: "dark:text-green-300",
          });
        }
        selectedJournals.forEach((journal) => {
          tags.push({
            name: "Journal",
            value: journal,
            backgroundColor: "bg-blue-100",
            textColor: "text-blue-800",
            darkBackgroundColor: "dark:bg-blue-900",
            darkTextColor: "dark:text-blue-300",
          });
        });

        const newResponseIndex = handleNewQuestion(query, tags);
        fetchQuestionAnswer(
          query,
          newResponseIndex,
          startYearInput,
          endYearInput,
          selectedJournals
        );
        setQuery("");
        // Do not reset selectedJournals to keep them persistent
        // setSelectedJournals([]);

        // Reset the textarea height to default after submission
        if (textareaRef.current) {
          textareaRef.current.style.height = "18px"; // Reset to default height
        }
      }
    };

    // Expose functions to parent components
    useImperativeHandle(ref, () => ({
      redoQuestion: (responseIndex) => {
        const existingEventSource = eventSourcesRef.current[responseIndex];
        if (existingEventSource) {
          existingEventSource.close();
          delete eventSourcesRef.current[responseIndex];
        }
      },
      fetchAnswer: (question, responseIndex, startYear, endYear) => {
        // Close existing EventSource if any
        const existingEventSource = eventSourcesRef.current[responseIndex];
        if (existingEventSource) {
          existingEventSource.close();
          delete eventSourcesRef.current[responseIndex];
        }

        // Re-issue the question
        fetchQuestionAnswer(question, responseIndex, startYear, endYear);
      },
    }));

    return (
      <div
        className="fixed bottom-6 z-50 transition-all duration-300 ease-in-out"
        style={{
          left: isSourceCollapsed ? "50%" : "37.5%",
          transform: "translateX(-50%)",
          width: "calc(100% - 2rem)",
          maxWidth: "600px",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <InputSection
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          textareaRef={textareaRef}
          startYearInput={startYearInput}
          setStartYearInput={setStartYearInput}
          endYearInput={endYearInput}
          setEndYearInput={setEndYearInput}
          selectedJournals={selectedJournals}
          setSelectedJournals={setSelectedJournals}
        />
      </div>
    );
  }
);

export default Controller;
